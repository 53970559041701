import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Row } from "antd";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Banner from "../../../components/Banner/Banner";

import photoFradique from "../../../assets/images/Dr-Fradique-Moreira@2x.png";
import photoRui from "../../../assets/images/Dr-Rui-Barreto@2x.png";
import photoMiguel from "../../../assets/images/Dr-Miguel-Grunho@2x.png";
import photoWilliam from "../../../assets/images/William-Osler@2x.png";
import photoRegulation from "../../../assets/images/Footer@2x.png";
import photoRegulationMobile from "../../../assets/images/Footer-mobile@2x.png";
import photoPrizes from "../../../assets/images/Premios@2x.png";

import "./Home.css";

function Home() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  return (
    <div className="home">
      <Helmet>
        <title>Concurso de Casos Clínicos em Neurologia</title>
        <meta
          name="description"
          content="Com o intuito de promover o desenvolvimento do raciocínio clínico, a Zambon organiza, este ano, o 'I Concurso de Casos Clínicos em Neurologia'."
        />
        <meta name="keywords" content="concursos neurologia, zambon casos clínicos, concurso casos clínicos em neurologia" />
      </Helmet>
      <Banner />

      {/*<Row id="premios" className="container">
        <Col span={24}>
          <div className="prizes_row container p-0">
            <Row>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <img src={photoPrizes} alt="Prémios Background" className="w-100" style={{ maxWidth: width < 1000 ? 400 : "auto" }} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={16} xl={16} className="prizes_text_div p-20">
                <h1 className="title white" style={{ textAlign: width < 900 ? "center" : "left" }}>
                  PRÉMIOS
                </h1>
                <p className="text white" style={{ textAlign: width < 900 ? "center" : "left" }}>
                  O vencedor será premiado com uma inscrição no congresso da EAN 2026 "12th Congress of the European Academy of Neurology", que decorrerá de 27 a 30 de junho em
                  Genebra, ficando as despesas de deslocação e estadia a cargo da Zambon.
                </p>
                <p className="text white" style={{ textAlign: width < 900 ? "center" : "left" }}>
                  Ainda, será oferecido um Curso de “Comunicação médica e apresentação gráfica” aos 5 finalistas do Concurso, selecionados pelo Comité de Especialistas, de forma a
                  que se preparem para a apresentação final, que decorrerá em final de maio 2025 (data a definir), que ocorrerá na edição seguinte do Curso de Internos da Zambon.
                </p>
                <p className="text white" style={{ textAlign: width < 900 ? "center" : "left" }}>
                  Partilhamos consigo a citação de William Osler que esteve na mente da nossa Equipa durante todo o processo da criação deste Concurso:
                </p>
                <Row gutter={24} align="center" className="mt-40">
                  <Col xs={24} sm={24} md={24} lg={4} xl={4} className="img_prizes">
                    <img src={photoWilliam} alt="Prémios Imagem" />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                    <p className="quote mb-0" style={{ textAlign: width < 900 ? "center" : "left" }}>
                      “Medicine is learned by the bedside
                      {width > 900 && <br />} and not in the classroom”
                    </p>
                    <p style={{ textAlign: width < 900 ? "center" : "left" }} className="text white mt-0">
                      William Osler
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>*/}

      {/*<Row id="prazos" gutter={24} className="container m-auto">
        <Col xs={24} sm={24} md={24} lg={12} xl={12} className="mt-20 mb-20" align={width < 900 ? "center" : "left"}>
          <div className="bg-committee p-20 d-flex flex-column jc-center ai-center">
            <p className="title text-center white bold mb-0" style={{ textAlign: width < 900 ? "center" : "left" }}>
              PRAZOS
            </p>
            <div className="p-20" style={{ maxWidth: 700 }}>
              <Row className="mt-20 mb-20">
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                  <p className="white bold f-18 m-0" style={{ textDecoration: "line-through" }}>
                    02/07/2024:
                  </p>
                </Col>
                <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                  <p className="white f-18 m-0" style={{ textDecoration: "line-through" }}>
                    Abertura do prazo para entrega dos casos clínicos em formato descritivo .pdf
                  </p>
                </Col>
              </Row>

              <Row className="mt-20 mb-20">
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                  <p className="white bold f-18 m-0" style={{ textDecoration: "line-through" }}>
                    15/11/2024:
                  </p>
                </Col>
                <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                  <p className="white f-18 m-0" style={{ textDecoration: "line-through" }}>
                    Fim do prazo para enviar a descrição dos casos clínicos
                  </p>
                </Col>
              </Row>

              <Row className="mt-20 mb-20">
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                  <p className="white bold f-18 m-0">15/01/2025:</p>
                </Col>
                <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                  <p className="white f-18 m-0">Anúncio dos 5 finalistas</p>
                </Col>
              </Row>

              <Row className="mt-20 mb-20">
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                  <p className="white bold f-18 m-0">01/02/2025- 30/04/2025:</p>
                </Col>
                <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                  <p className="white f-18 m-0">Curso de “Comunicação médica e apresentação gráfica" para os 5 finalistas</p>
                </Col>
              </Row>

              <Row className="mt-20 mb-20">
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                  <p className="white bold f-18 m-0">01/04/2025:</p>
                </Col>
                <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                  <p className="white f-18 m-0">
                    Fim do prazo para enviar a apresentação dos casos em formato .ppt (a trabalhar no curso de Curso de “Comunicação médica e apresentação gráfica")
                  </p>
                </Col>
              </Row>

              <Row className="mt-20 mb-20">
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                  <p className="white bold f-18 m-0">30/05/2025:</p>
                </Col>
                <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                  <p className="white f-18 m-0">
                    Apresentação do casos clínicos no Curso de Internos de Neurologia de 2025, anúncio dos vencedores e cerimónia de entrega de prémios
                  </p>
                </Col>
              </Row>

              <Row className="mt-20 mb-20">
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                  <p className="white bold f-18 m-0">27/06/2026:</p>
                </Col>
                <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                  <p className="white f-18 m-0">Congresso EAN 2026 em Genebra</p>
                </Col>
              </Row>

              <Row className="mt-30" gutter={[24]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} align="right" className="mobile-center">
                  <Link to={"/inscricao"}>
                    <Button className="primary_button">PARTICIPAR</Button>
                  </Link>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="d-flex jc-center ai-center">
                  <Link to={"/regulamento"}>
                    <Button className="second_button">REGULAMENTO</Button>
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} className="mt-20 mb-20" align={width < 900 ? "center" : "left"}>
          <div className="bg-committee p-20 d-flex flex-column">
            <p className="title text-center white bold mb-0">COMITÉ CIENTÍFICO</p>
            <div className="p-20 d-flex flex-column m-auto" style={{ maxWidth: 600 }}>
              <p className="white f-18 text-center">O Comité Científico de Avaliação será composto pelos seguintes especialistas com competência na área da Neurologia:</p>
              <div className="d-flex flex-column">
                <Row gutter={[24]} className="mt-15 mb-15">
                  <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                    <img src={photoFradique} className="w-100 maxw-200 mt-10 mb-10" />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                    <p className="f-18 white bold mt-0 mb-0">Coordenador</p>
                    <p className="f-22 white bold mt-0 mb-5">Dr. Fradique Moreira</p>
                    <p className="f-18 white mt-0">
                      Especialista em Neurologia.
                      <br /> <i>Centro Hospitalar e Universitário de Coimbra</i>
                    </p>
                  </Col>
                </Row>
                <Row gutter={[24]} className="mt-15 mb-15">
                  <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                    <img src={photoRui} className="w-100 maxw-200 mt-10 mb-10" />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                    <p className="f-18 white bold mt-0 mb-0">Coordenador</p>
                    <p className="f-22 white bold mt-0 mb-5">Dr. Rui Barreto</p>
                    <p className="f-18 white mt-0">
                      Especialista em Neurologia.
                      <br /> <i>Centro Hospitalar Universitário de Lisboa Norte</i>
                    </p>
                  </Col>
                </Row>
                <Row gutter={[24]} className="mt-15">
                  <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                    <img src={photoMiguel} className="w-100 maxw-200 mt-10 mb-10" />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                    <p className="f-18 white bold mt-0 mb-0">Coordenador</p>
                    <p className="f-22 white bold mt-0 mb-5">Dr. Miguel Grunho</p>
                    <p className="f-18 white mt-0">
                      Especialista em Neurologia.
                      <br /> <i>Hospital Garcia de Orta</i>
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>*/}

      <Row id="regulation" className="regulation">
        <div className="regulation_bg mobile-hidden">
          <Col xs={24} sm={24} md={24} lg={10} xl={10} className="d-flex jc-end ai-end">
            <img src={photoRegulation} className="w-100" />
          </Col>
        </div>
        <div className="container" style={{ padding: width < 900 ? 0 : "" }}>
          <Col xs={24} sm={24} md={24} lg={18} xl={18} style={{ padding: width < 900 ? 20 : "" }}>
            <h1 className="title orange">REGULAMENTO</h1>
            <p className="text">Conheça detalhadamente os critérios que deverá cumprir</p>
            <Row gutter={24} className="reverse_regulation">
              <Col xs={24} sm={24} md={24} lg={16} xl={16} className="regulation_list">
                <h1 className="title">Regras de apresentação</h1>
                <Divider orientation="left" orientationMargin={0} className="divider_regulation_list" />
                <ol type="a">
                  <li className="text-left">Os casos clínicos deverão versar sobre Doenças de Movimento ou Patologia Neuromuscular e ser redigidos e apresentados em português.</li>
                  <li className="text-left">
                    Os concorrentes devem enviar um documento em formato .doc ou .pdf com a descrição do caso clínico e, posteriormente, os 5 finalistas, selecionados pelo Comité
                    de Peritos, devem enviar a apresentação do caso.
                  </li>
                  <li className="text-left">
                    No caso clínico apresentado pelo Participante não deverão constar dados pessoais (nome do autor, local de trabalho, entre outros), ou seja, o documento deverá
                    ser completamente anónimo, permitindo assim uma apreciação objetiva e imparcial.
                  </li>
                  <li className="text-left">Não serão admitidos a Concurso Casos Clínicos que contenham dados pessoais que possam levar à identificação do doente.</li>
                  <li className="text-left">Não serão admitidos ao Concurso os Casos Clínicos que contenham dados que não sejam manifestamente precisos ou estejam incompletos.</li>
                  <li className="text-left">
                    Se algum caso contiver a descrição de um acontecimento adverso, o autor deverá comunicá-lo no portal{" "}
                    <Link to="https://www.infarmed.pt/web/infarmed/submissaoram" target="_blank">
                      RAM
                    </Link>
                  </li>
                </ol>
                <p className="text">
                  A não observância dos termos e condições descritos em qualquer das alíneas a) a e) atrás descritas determina a não aceitação da candidatura do Participante a
                  Concurso.
                </p>
                <Link to="/regulamento">
                  <span className="link">» Visitar página do Regulamento</span>
                </Link>
              </Col>
            </Row>
            <Row className="regulation_buttons mb-90" align="center">
              {/* <Link to="/inscricao">
                <Button className="primary_button">PARTICIPAR</Button>
              </Link> */}
            </Row>
          </Col>
          {width < 900 && (
            <Col span={24} align="end" className="d-flex jc-center ai-center p-0">
              <img src={photoRegulationMobile} className="w-100 mt-40" />
            </Col>
          )}
        </div>
      </Row>
    </div>
  );
}

export default Home;
