import { Button, Col, Row } from "antd";
import "./Banner.css";
import logoHeroSVG from "../../assets/images/Concurso-casos-clinicos-em-neurologia-2-edicao.svg";
import { Link } from "react-router-dom";
import { Link as AnchorLink, animateScroll as scroll } from "react-scroll";

const Banner = () => {
  return (
    <Row className="banner_home">
      <Col span={24} className="container">
        <Row>
          <Col xs={24} sm={24} md={24} lg={8} xl={8} align="center">
            <img src={logoHeroSVG} className="logo_banner" />
            <p className="text">A Zambon dá como terminada a 2ª Edição do Concurso de Casos Clínicos em Neurologia. Obrigado aos participantes</p>
            <div className="banner_buttons">
              {/* <Link to={"/inscricao"}>
                <Button className="primary_button">PARTICIPAR</Button>
              </Link> 

              <AnchorLink activeClass="active" to={"regulation"} spy={true} smooth={true} offset={-70} duration={500}>
                <Button className="second_button">REGULAMENTO</Button>
              </AnchorLink>*/}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default Banner;
